<template>
  <app-page :toolbar="toolbar" :list="list" :creator="creator" />
</template>

<script>
import request from '@/utils/request';

export default {
  name: 'Buy',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '60px',
        model: {
          name: undefined
        },
        fields: [
          { label: '姓名', prop: 'name', placeholder: '请输入要搜索的姓名' }
        ]
      },
      list: {
        method: 'post',
        url: '/hairdressing/vip',
        fields: [
          { label: '姓名', prop: 'name', width: 120 },
          { label: '部门', prop: 'departName' },
          { label: '电话', prop: 'telephone', width: 220, align: 'right' },
          { label: '身份证号', prop: 'idCard', width: 260, align: 'right' },
          { label: '警号', prop: 'policeCard', width: 120, align: 'right' }
        ],
        action: false
      },
      creator: {
        width: '400px',
        title: '添加会员',
        text: '添加会员',
        size: 'small',
        labelWidth: '80px',
        submit: true,
        url: '/hairdressing/vip/create',
        model: {
          userId: undefined
        },
        rules: {
          userId: {
            required: true,
            message: '用户是必选的'
          }
        },
        fields: [
          {
            label: '用户',
            prop: 'userId',
            type: 'select',
            placeholder: '请输入用户',
            clearable: true,
            options: [],
            filterable: true,
            remote: true,
            // 远程搜索
            remoteMethod: this.getUsers,
            valueKey: 'id'
          }
        ]
      }
    };
  },
  methods: {
    async getUsers(query) {
      if (!query) {
        return;
      }
      const result = await request.post('/user/getAllList', {
        name: query,
        isActive: 1,
        isReg: 1
      });
      const options = result.data.records.map(item => {
        return {
          label: item.name,
          value: item.userid
        };
      });
      this.creator.fields[0].options = options;
    }
  }
};
</script>
